import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"

const TermsOfUse = ({}) => (
  <Layout>
    <section class="pt3 pb2-ns pb1 mt3 tc w-70-l  w-90 center">
        <div class="mb1 pa2 pt3 pb5 w-100 tl">
            <a name="top">
                <h1 class="tracked lh-title nowrap f3-ns f4 pb0 mb0">Site Terms of Use</h1>
            </a>
            <h2 class="f5">Last Modified: [05/29/2018]</h2>
            <h3 class="text lh-copy f5 mb1">Acceptance of the Terms of Use</h3>&nbsp; <p class="text lh-copy f5 mt1"> These
                terms and conditions are entered into by and between You and Frendli, Inc. (the “Company”, “we”, or “us”).
                The Terms of Use (as defined below), govern your access to the Frendli mobile application and related use
                of the website at www.frendli.com, the mobile and touch versions and any sites we have now or in the future
                that reference the Terms of Use including any content, functionality, and services offered on or through
                the aforementioned (collectively, the “Site”). Please read the Terms of Use carefully before you start to
                use the Site. By using the Site in any form or fashion, including, without limitation, using the Company’s
                services through the Site, making any purchase through the Site or signing up for an account with the
                Company, you accept and agree to the Terms of Use, including our Privacy Policy, found at <a href="/privacy-policy">http://www.frendli.com/privacy-policy</a>,
                incorporated herein by reference. The Privacy Policy, these terms and conditions, and any other terms
                contained herein or incorporated herein by reference, are collectively referred to as the “Terms of Use”.
                If you do not want to agree to these Terms of Use, you must not access or use the site and therefore must
                not use any frendli service, voucher or any other product or service through the site or open a frendli
                account. </p>
            <p class="text lh-copy f5 mt1"> The Site is a platform through which certain merchants (“Merchants”) sell
                vouchers (“Vouchers”) for goods, services, or experiences or provide a discount therefor (the “Merchant
                Offering”). Merchants are the sellers and issuers of the Merchant Offerings and are solely responsible to
                you for the care, quality, performance and delivery of the goods and services provided. Vouchers may be
                distributed by the Company or directly by the Merchant. More information about the distributor of a
                particular Voucher is available upon request. The Company is not an agent or representative of the
                Merchants. </p>
            <h3 class="text lh-copy f5">Changes to the Terms of Use</h3>&nbsp;&nbsp; <p class="text lh-copy f5 mt1"> We may
                revise and update these Terms of Use from time to time in our sole discretion. All changes are effective
                immediately when we post them. Your continued use of the Site following the posting of revised Terms of Use
                means that you accept and agree to the changes. You are expected to check this page from time to time so
                you are aware of any changes, as they are binding on you. </p>
            <h3 class="text lh-copy f5">Accessing the Site and Account Security&nbsp;</h3>
            <p class="text lh-copy f5 mt1"> We reserve the right to withdraw or amend this Site, and any service or
                material we provide on the Site, in our sole discretion without notice. We will not be liable if for any
                reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may
                restrict access to some parts of the Site, or the entire Site, to users, including registered users. <br/>
                <br/> You are responsible for: </p>
            <ul class="text lh-copy f5">
                <li class="text lh-copy f5">Making all arrangements necessary for you to have access to the Site.</li>
                <li class="text lh-copy f5">Ensuring that all persons who access the Site through your internet connection
                    and/or with your account credentials are aware of these Terms of Use and comply with them.</li>
            </ul>
            <p class="text lh-copy f5 mt1"> By accessing this Site, you agree that you are at least 18 year of age, you are
                able to create a binding obligation and you are not barred from receiving products or services under
                applicable law. To access the Site or some of the resources it offers, you may be asked to provide certain
                registration details or other information or credentials. It is a condition of your use of the Site that
                all the information you provide on or through the Site is correct, current, and complete at all times,
                including, without limitation, updated and valid credit card information. Out-of-date information will
                invalidate your account. Any violation of these Terms of Use, including, without limitation, failure to
                maintain updated and correct information about your account (e.g., valid credit card information) will
                cause your account to fall out of good standing and we may cancel your account in our sole discretion. If
                your account is cancelled, you may forfeit any pending, current, or future account credits, and any other
                forms of unredeemed value in your account without notice. You agree that all information you provide to
                register with this Site or otherwise, including but not limited to through the use of any interactive
                features on the Site, is governed by our <a href="/privacy-policy">Privacy Policy</a>, and you consent to
                all actions we take with respect to your information consistent with our <a href="/privacy-policy">Privacy
                    Policy</a>. </p>
            <p class="text lh-copy f5 mt1"> If you choose, or are provided with, a user name, password, or any other piece
                of information as part of our security procedures, you must treat such information as confidential, and you
                must not disclose it to any other person or entity. You also acknowledge that your account is personal to
                you and agree not to provide any other person with access to this Site or portions of it using your user
                name, password, or other security information. You agree to notify us immediately of any unauthorized
                access to or use of your user name or password or any other breach of security. You also agree to ensure
                that you exit from your account at the end of each session. You should use particular caution when
                accessing your account from a public or shared computer so that others are not able to view or record your
                password or other personal information.&nbsp; You are solely responsible for any activity that occurs under
                your account. </p>
            <p class="text lh-copy f5 mt1"> We have the right to disable any user name, password, or other identifier,
                whether chosen by you or provided by us, at any time if, in our opinion, you have violated any provision of
                these Terms of Use. </p>
            <h3 class="text lh-copy f5">Intellectual Property Rights</h3>
            <p class="text lh-copy f5 mt1"> The Site and its entire contents, features, and functionality (including but
                not limited to all information, software, text, displays, images, video, and audio, and the design,
                selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such
                material and are protected by United States and international copyright, trademark, patent, trade secret,
                and other intellectual property or proprietary rights laws. </p> <span class="text lh-copy f5"> These Terms
                of Use permit you to use the Site for your personal, non-commercial use only. You may not use the Site to
                directly or indirectly compete with the Company. In addition, you must not reproduce, distribute, modify,
                create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any
                of the material on our Site, except as follows: </span> &nbsp;&nbsp;&nbsp;&nbsp;<ul class="text lh-copy f5">
                <li class="text lh-copy f5">Your computer may temporarily store copies of such materials in RAM incidental
                    to your accessing and viewing those materials.</li>
                <li class="text lh-copy f5">You may store files that are automatically cached by your Web browser for
                    display enhancement purposes.</li>
                <li class="text lh-copy f5">You may print one copy of a reasonable number of pages of the Site for your own
                    personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
                <li class="text lh-copy f5">If we provide desktop, mobile, or other applications for download, you may
                    download a single copy to your computer or mobile device solely for your own personal, non-commercial
                    use, provided you agree to be bound by our end user license agreement for such applications.</li>
            </ul> <span class="text lh-copy f5"> You must not: </span> &nbsp;&nbsp;&nbsp;&nbsp;<ul class="text lh-copy f5">
                <li class="text lh-copy f5">Modify copies of any materials from the Site.</li>
                <li class="text lh-copy f5">Delete or alter any copyright, trademark, or other proprietary rights notices
                    from copies of materials from the Site.</li>
                <li class="text lh-copy f5">You must not access or use for any commercial purposes any part of the Site or
                    any services or materials available through the Site.</li>
            </ul>
            <p class="text lh-copy f5 mt1"> If you print, copy, modify, download, or otherwise use or provide any other
                person with access to any part of the Site in breach of the Terms of Use, your right to use the Site will
                stop immediately and you must, at our option, return or destroy any copies of the materials you have made.
                No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all
                rights not expressly granted are reserved by the Company. Any use of the Site not expressly permitted by
                these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
            </p>
            <h3 class="text lh-copy f5">Trademarks&nbsp;</h3>
            <p class="text lh-copy f5 mt1">The Company name, the terms, and all related names, logos, product and service
                names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use
                such marks without the prior written permission of the Company. All other names, logos, product and service
                names, designs, and slogans on this Site are the trademarks of their respective owners. </p>
            <h3 class="text lh-copy f5">Prohibited Uses&nbsp;&nbsp;</h3>
            <p class="text lh-copy f5 mt1 mb0"> You may use the Site only for lawful purposes and in accordance with these
                Terms of Use. You agree not to use the Site: </p> &nbsp;<ul class="text lh-copy f5 mt0"> &nbsp; <li class="text lh-copy f5">In
                    any way that violates any applicable federal, state, local, or international law or regulation
                    (including, without limitation, any laws regarding the export of data or software to and from the US or
                    other countries).</li>
                <li class="text lh-copy f5">To send, knowingly receive, upload, download, use, or re-use any material that
                    does not comply with the Content Standards (as defined below) set out in these Terms of Use.</li>
                <li class="text lh-copy f5">To impersonate or attempt to impersonate the Company, a Company employee,
                    another user, or any other person or entity (including, without limitation, by using email addresses
                    associated with any of the foregoing).</li>
                <li class="text lh-copy f5">To engage in any other conduct that restricts or inhibits anyone’s use or
                    enjoyment of the Site, or which, as determined by us, may harm the Company or users of the Site or
                    expose them to liability.</li>
            </ul>
            <p class="text lh-copy f5 mt1 mb0"> Additionally, you agree not to: </p>
            <ul class="text lh-copy f5">
                <li class="text lh-copy f5">Use the Site in any manner that could disable, overburden, damage, or impair
                    the site or interfere with any other party’s use of the Site, including their ability to engage in real
                    time activities through the Site.</li>
                <li class="text lh-copy f5">Use any robot, spider, or other automatic device, process, or means to access
                    the Site for any purpose, including monitoring or copying any of the material on the Site.</li>
                <li class="text lh-copy f5">Use any manual process to monitor or copy any of the material on the Site or
                    for any other unauthorized purpose without our prior written consent.</li>
                <li class="text lh-copy f5">Use any device, software, or routine that interferes with the proper working of
                    the Site.</li>
                <li class="text lh-copy f5">Introduce any viruses, Trojan horses, worms, logic bombs, or other material
                    that is malicious or technologically harmful.</li>
                <li class="text lh-copy f5">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                    parts of the Site, the server on which the Site is stored, or any server, computer, or database
                    connected to the Site.</li>
                <li class="text lh-copy f5">Attack the Site via a denial-of-service attack or a distributed
                    denial-of-service attack.</li>
                <li class="text lh-copy f5">Otherwise attempt to interfere with the proper working of the Site.</li>
                <li class="text lh-copy f5">Submit personal information through the Site, except when the Company expressly
                    asks you to provide such information.</li>
                <li class="text lh-copy f5">Submit any information that is purposely inaccurate, commits fraud or falsifies
                    information in connection with your account.</li>
                <li class="text lh-copy f5">Create multiple accounts.</li>
                <li class="text lh-copy f5">Access data not intended for you, such as logging into a server or an account
                    which you are not authorized to access;</li>
                <li class="text lh-copy f5">Scan or monitoring the Site for data gathering purposes in an effort to track
                    sales, usage, aggregate offering information, pricing information, or similar data.</li>
                <li class="text lh-copy f5">Exceed or attempt to exceed quantity limits when purchasing Merchant Offerings
                    or Vouchers, or otherwise using any account to purchase Merchant Offerings or Vouchers for resale or
                    for speculative, false, fraudulent, or any other purpose not expressly permitted by these Terms of Use
                    and the terms of a specific offer on the Site.</li>
                <li class="text lh-copy f5">Deep-link to any portion of the Site (including, without limitation, the
                    purchase path for any Voucher) without our express written permission;</li>
                <li class="text lh-copy f5">Acting illegally or maliciously against the business interests or reputation of
                    the Company, our Merchants, or our services.</li>
            </ul>
            <h3 class="text lh-copy f5">User Contributions&nbsp;</h3>
            <p class="text lh-copy f5 mt1"> The Site may contain personal web pages or profiles, video upload capabilities,
                and other interactive features (collectively, “Interactive Services”) that allow users to post, submit,
                publish, display, or transmit (hereinafter, “post”) content or materials (collectively, “User
                Contributions”) on or through the Site. All User Contributions must comply with the Content Standards set
                out in these Terms of Use. Any User Contribution you post to the site will be considered non-confidential
                and non-proprietary. By providing any User Contribution on the Site, you grant us and our affiliates and
                service providers, and each of their and our licensees, successors, and assigns the right to use,
                reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material.
            </p>
            <p class="text lh-copy f5 mt1"> If you provide any User Contributions, you will not upload, post, or otherwise
                make available on the Site any material protected by copyright, trademark, or other proprietary right
                without the express permission of the owner of the copyright, trademark, or other proprietary right. The
                Company does not have any express burden or responsibility to provide you with indications, markings, or
                anything else that may aid you in determining whether the material in question is copyrighted or
                trademarked. You will be solely liable for any damage resulting from any infringement of copyrights,
                trademarks, proprietary rights, or any other harm resulting from such a submission. In addition, if you
                provide any User Contributions, you represent and warrant that: (a) you are the creator of the User
                Contributions; or (b) if you are acting on behalf of the creator, that you have (i) express, advance
                authority from the creator to submit or post the User Contributions, and that they have waived any moral
                rights in such User Contributions, and (ii) all rights necessary to grant the licenses and grants in these
                Terms of Use. You further represent and warrant (or, if you are acting on behalf of the creator of the User
                Contributions, you have ensured that the creator represents and warrants) that the use and sharing of the
                User Contributions for the purposes you have selected will not violate or infringe any copyrights,
                trademarks, or any other intellectual property rights or rights of third parties, including, without
                limitation, the rights of publicity or privacy. You represent and warrant that you will not upload, post,
                transmit, or otherwise make available User Contributions that are unlawful, harmful, tortious, threatening,
                abusive, harassing, hateful, racist, infringing, pornographic, obscene, violent, misleading, defamatory or
                libelous, invasive of the privacy of another person, or violative of any third-party rights; and that you
                will not upload, post, transmit, or otherwise make available User Contributions that contain any material
                that harbors viruses or any other computer codes, files, or programs designed to intercept, misappropriate,
                interrupt, destroy or limit the functionality of any software or computer equipment. You further represent
                and warrant that all of your User Contributions do and will comply with these Terms of Use. </p>
            <p class="text lh-copy f5 mt1"> You understand and acknowledge that you are responsible for any User
                Contributions you submit or contribute, and you, not the Company, have full responsibility for such
                content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or
                liable to any third party for the content or accuracy of any User Contributions posted by you or any other
                user of the Site. &nbsp;&nbsp;&nbsp;&nbsp;Any User Contributions of any kind made by you or any third-party
                are made by the respective author(s) or distributor(s) and not by the Company. </p>
            <p class="text lh-copy f5 mt1"> Other users may post User Contributions that are inaccurate, misleading, or
                deceptive. The Company does not endorse and is not responsible for any User Contributions, and will not be
                liable for any loss or damage caused by your reliance on such User Contributions (including, without
                limitation, reliance on any information set forth in a Merchant Offering). User Contributions reflect the
                opinions of the person submitting it and may not reflect the opinion of the Company. The Company does not
                control or endorse any User Contributions, and specifically disclaims any liability concerning or relating
                to your contribution of, use of, or reliance on any User Contributions and any actions resulting from your
                participation in any part of the Site, including, without limitation, any objectionable User Contributions.
            </p>
            <h3 class="text lh-copy f5">Monitoring and Enforcement; Termination&nbsp;</h3>
            <p class="text lh-copy f5 mt1 mb0">We have the right to:</p>
            <ul class="text lh-copy f5 mt0"> &nbsp; <li class="text lh-copy f5">Remove or refuse any User Contributions for
                    any or no reason in our sole discretion.</li>
                <li class="text lh-copy f5">Take any action with respect to any User Contribution that we deem necessary or
                    appropriate in our sole discretion, including if we believe that such User Contribution violates the
                    Terms of Use, including the Content Standards, infringes any intellectual property right or other right
                    of any person or entity, threatens the personal safety of users of the Site or the public, or could
                    create liability for the Company.</li>
                <li class="text lh-copy f5">Disclose your identity or other information about you to any third party who
                    claims that material posted by you violates their rights, including their intellectual property rights
                    or their right to privacy.</li>
                <li class="text lh-copy f5">Take appropriate legal action, including without limitation, referral to law
                    enforcement, for any illegal or unauthorized use of the Site.</li>
                <li class="text lh-copy f5">Terminate or suspend your access to all or part of the Site for any violation
                    of these Terms of Use.</li>
            </ul>
            <p class="text lh-copy f5 mt1">Without limiting the foregoing, we have the right to cooperate fully with any
                law enforcement authorities or court order requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS THE COMPANY
                AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
                COMPANY/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER THE
                COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
            <p class="text lh-copy f5 mt1">Upon termination, the provisions of these Terms of Use that are, by their
                nature, intended to survive termination (e.g., any disclaimers, all limitations of liability, and all
                indemnities) shall survive. We also reserve the right to change or discontinue any aspect or feature of our
                services or the Site, including, without limitation, requirements for its use.</p> &nbsp; <h3 class="text lh-copy f5">Content
                Standards&nbsp;</h3>
            <p class="text lh-copy f5 mt1">These content standards provided below (the “Content Standards”) apply to any
                and all User Contributions and use of Interactive Services. User Contributions must in their entirety
                comply with all applicable federal, state, local, and international laws and regulations. Without limiting
                the foregoing, User Contributions must not:</p>
            <ul class="text lh-copy f5">
                <li class="text lh-copy f5">Contain any material that is defamatory, obscene, indecent, abusive, offensive,
                    harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                <li class="text lh-copy f5">Promote sexually explicit or pornographic material, violence, or discrimination
                    based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
                <li class="text lh-copy f5">Infringe any patent, trademark, trade secret, copyright, or other intellectual
                    property or other rights of any other person.</li>
                <li class="text lh-copy f5">Violate the legal rights (including the rights of publicity and privacy) of
                    others or contain any material that could give rise to any civil or criminal liability under applicable
                    laws or regulations or that otherwise may be in conflict with these Terms of Use.</li>
                <li class="text lh-copy f5">Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                <li class="text lh-copy f5">Impersonate any person, or misrepresent your identity or affiliation with any
                    person or organization.</li>
            </ul>
            <h3 class="text lh-copy f5">Reliance on Information Posted</h3>
            <p class="text lh-copy f5 mt1">The information presented on or through the Site is made available solely for
                general information purposes. We do not warrant the accuracy, completeness, or usefulness of this
                information. Any reliance you place on such information is strictly at your own risk. We disclaim all
                liability and responsibility arising from any reliance placed on such materials by you or any other visitor
                to the Site, or by anyone who may be informed of any of its contents.</p> &nbsp; <h3 class="text lh-copy f5">Changes
                to the Site</h3> &nbsp;<p class="text lh-copy f5 mt1"> We may update the content on this Site from time to
                time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out
                of date at any given time, and we are under no obligation to update such material. </p>
            <h3 class="text lh-copy f5">Information About You and Your Visits to the Site</h3>
            <p class="text lh-copy f5 mt1"> All information we collect on this Site is subject to our <a href="/privacy-policy">Privacy
                    Policy</a>. By using the Site, you consent to all actions taken by us with respect to your information
                in compliance with the <a href="/privacy-policy">Privacy Policy</a>. </p>
            <h3 class="text lh-copy f5">Links from the Site</h3>
            <p class="text lh-copy f5 mt1"> The Site contains links to other sites and resources provided by third parties
                and these links are provided for your convenience only and not because we endorse or have an opinion about
                the contents on such websites (including, without limitation, of those Merchants). This includes links
                contained in advertisements, including banner advertisements and sponsored links. We have no control over
                the contents of those sites or resources, and accept no responsibility for them or for any loss or damage
                that may arise from your use of them. If you decide to access any of the third-party websites linked to
                this Site, you do so entirely at your own risk and subject to the terms and conditions of use for such
                websites. We are not responsible or liable, directly or indirectly, for any damage, loss, or liability
                caused or alleged to be caused by or in connection with any use of or reliance on any content, Merchant
                Offerings, products, or services available on or through any such linked site or resource. </p>
            <h3 class="text lh-copy f5">Geographic Restrictions&nbsp;&nbsp; <p class="text lh-copy f5 mt1"> The owner of
                    the Site is based in the state of California in the United States. We provide this Site for use only by
                    persons located in the United States. We make no claims that the Site or any of its content is
                    accessible or appropriate outside of the United States. Access to the Site may not be legal by certain
                    persons or in certain countries. If you access the Site from outside the United States, you do so on
                    your own initiative and are responsible for compliance with local laws. </p>
            </h3>
            <h3 class="text lh-copy f5">Disclaimer of Warranties</h3>
            <p class="text lh-copy f5 mt1"> You understand that we cannot and do not guarantee or warrant that files
                available for downloading from the internet or the Site will be free of viruses or other destructive code.
                You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular
                requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means
                external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE
                WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
                OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
                OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE
                OR DUE TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT. &nbsp; </p>
            <p class="text lh-copy f5 mt1"> YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE
                ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY
                OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE
                (INCLUDING, WITHOUT LIMITATION, MERCHANT OFFERINGS, VOUCHERS OR PRODUCTS OR SERVICES AVAILABLE IN
                CONNECTION THEREWITH OR DESCRIPTIONS THEREOF) WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
                THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL
                OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. &nbsp; </p>
            <p class="text lh-copy f5 mt1"> TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. </p>
            <p class="text lh-copy f5 mt1"> &nbsp;&nbsp;&nbsp;&nbsp;THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. </p>
            <h3 class="text lh-copy f5">Limitation on Liability</h3>
            <p class="text lh-copy f5 mt1">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
                AFFILIATES, ITS MERCHANTS, ITS PARTNERS OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
                OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
                WITH YOUR USE, OR INABILITY TO USE, THE SITE, THE MERCHANT OFFERINGS, VOUCHERS (INCLUDING PRODUCTS OR
                SERVICES PROVIDED IN CONNECTION THEREWITH), ANY WEBSITES LINKED TO THE SITE, THESE TERMS OF USE, ANY
                CONTENT ON THE SITE OR SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
                EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE,
                LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
                OTHERWISE, EVEN IF FORESEEABLE. </p> &nbsp; <p class="text lh-copy f5 mt1"> THE FOREGOING DOES NOT AFFECT
                ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. </p>
            <h3 class="text lh-copy f5">Indemnification</h3>
            <p class="text lh-copy f5 mt1"> You agree to defend, indemnify, and hold harmless the Company, its affiliates,
                licensors, and service providers, and its and their respective officers, directors, employees, contractors,
                agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages,
                judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of
                or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to,
                your User Contributions, any use of the Site’s content, services, and products other than as expressly
                authorized in these Terms of Use or your use of any information obtained from the Site. </p>
            <p class="text lh-copy f5 mt1"> You acknowledge and agree that the Company is solely serving as an intermediary
                between you and the Merchant. You are solely responsible for your interactions with Merchants and other
                users of the Site. To the extent permitted under applicable laws, you hereby release the Company from any
                and all claims or liability related to any product or service of a Merchant, regardless of whether such
                product or service is a Merchant Offering available through the Site, any action or inaction by a Merchant,
                including, without limitation, but not limited to any harm caused to you by action or inaction of a
                Merchant, a Merchant’s failure to comply with applicable law and/or failure to abide by the terms of a
                Merchant Offering or any other product or service purchased or obtained by you from the Merchant, and any
                conduct, speech or User Contributions, whether online or offline, of any other third-party. </p>
            <h3 class="text lh-copy f5">Governing Law and Jurisdiction</h3>
            <p class="text lh-copy f5 mt1"> All matters relating to the Site and these Terms of Use and any dispute or
                claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims),
                shall be governed by and construed in accordance with the internal laws of the State of California without
                giving effect to any choice or conflict of law provision or rule (whether of the State of California or any
                other jurisdiction). Any legal suit, action, or proceeding arising out of, or related to, these Terms of
                Use or the Site shall be instituted exclusively in the federal courts of the United States or the courts of
                the State of California in each case located in the City of San Diego and County of San Diego. You waive
                any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
            </p>
            <h3 class="text lh-copy f5">Waiver and Severability</h3>&nbsp; <p class="text lh-copy f5 mt1"> No waiver by the
                Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing
                waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company
                to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or
                provision. If any provision of these Terms of Use is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or
                limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full
                force and effect. </p>
            <h3 class="text lh-copy f5">Force Majeure</h3>
            <p class="text lh-copy f5 mt1"> The Company shall be excused from performance under these Terms of Use, to the
                extent it or a Merchant is prevented or delayed from performing, in whole or in part, as a result of an
                event or series of events caused by or resulting from: (a) weather conditions or other elements of nature
                or acts of God; (b) acts of war, acts of terrorism, insurrection, riots, civil disorders, or rebellion; (c)
                quarantines or embargoes; (d) labor strikes; (e) error or disruption to major computer hardware or networks
                or software failures; or (f) other causes beyond the reasonable control of the Company or a Merchant, as
                applicable. </p>
            <h3 class="text lh-copy f5">Assignment</h3>
            <p class="text lh-copy f5 mt1"> You may not assign these Terms of Use, or any rights, benefits, or obligations
                hereunder, by operation of law or otherwise, without the express written permission of the Company. Any
                attempted assignment that does not comply with these Terms of Use shall be null and void. The Company may
                assign these Terms of Use, in whole or in part, to any third-party in its sole discretion. </p>
            <h3 class="text lh-copy f5">No Class Action Matters</h3>
            <p class="text lh-copy f5 mt1"> We each agree that we shall bring any dispute or claim between you and the
                Company and /or its subsidiaries, affiliates, and/or any of their respective members, officers, directors,
                and employees arising out of, relating in any way to, or in connection with the Terms of Use, the Site or
                your use of the Site (“Dispute”) in our respective individual capacities and not as a plaintiff or class
                member in any purported class, representative proceeding or as an association. In addition, we each agree
                that Disputes shall be arbitrated only on an individual basis and not in a class, consolidated, or
                representative action and that the arbitrator may award relief (including injunctive relief) only on an
                individual basis. </p>
            <h3 class="text lh-copy f5">Entire Agreement</h3>
            <p class="text lh-copy f5 mt1"> Except for any Terms and Conditions entered into by and between the Company and
                a Merchant (as applicable to the relationship between the Company and a Merchant), the Terms of Use and our
                <a href="/privacy-policy">Privacy Policy</a> constitute the sole and entire agreement between you and
                Frendli, Inc. regarding the Site and supersede all prior and contemporaneous understandings, agreements,
                representations, and warranties, both written and oral, regarding the Site. </p>
            <h3 class="text lh-copy f5">Your Comments and Concerns</h3>
            <p class="text lh-copy f5 address"> This Site is operated by Frendli, Inc., 1150 Garden View Rd. #231561,
                Encinitas, CA 92024. </p>
            <h3 class="text lh-copy f5">All other feedback, comments, requests for technical support, and other
                communications relating to the Site should be directed to:</h3>
            <p class="text lh-copy f5 mt1"><a href="mailto:help@frendli.com">help@frendli.com</a></p>
        </div>
    </section>
  </Layout>
)

export default TermsOfUse
